import {Component} from '@angular/core';
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@capacitor/splash-screen';
import {ProfileService} from './shared';
import {environment} from '../environments/environment';
import {EventNode} from 'raain-model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Radars',
      url: '/radars',
      icon: 'wifi'
    },
    {
      title: 'Rains',
      url: '/rains',
      icon: 'rainy'
    },
    // {
    //   title: 'Events',
    //   url: '/events',
    //   icon: 'notifications-outline'
    // },
    {
      title: 'Settings',
      url: '/profile',
      icon: 'settings'
    },
    {
      title: 'Sign out',
      url: '/logout',
      icon: 'log-out'
    }
  ];

  private notifications: EventNode[];

  constructor(
    private platform: Platform,
    private profile: ProfileService
  ) {
    this.notifications = [];
    this.initializeApp();
  }

  async initializeApp() {
    this.profile.defaultUrlForAPI = environment.serverUrl;
    await this.platform.ready();
    await this.initFidj();
    await SplashScreen.hide();
  }

  async initFidj() {
    if (this.profile.isLoggedIn()) {
      return;
    }

    await this.profile.logout(environment.fidjKey, environment.production);
  }

  async getNotifications() {
    this.notifications = await this.profile.getAllNotifications();
  }

  getBadges(page: { icon: string; title: string; url: string }) {
    if (page.title !== 'Events') {
      return 0;
    }

    return this.notifications?.length;
  }
}
