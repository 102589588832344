import {Component, EventEmitter, Input, NgZone, Output} from '@angular/core';
import {XYType} from '../xytype';
import {CompareManager} from '../tools';

@Component({
  selector: 'raain-compare-stack',
  templateUrl: './raain-compare-stack.component.html',
  styleUrls: ['./raain-compare-stack.component.scss'],
})
export class RaainCompareStackComponent {

  @Input()
  public compareManager: CompareManager;

  @Input()
  public currentHeight: number;

  @Output()
  public selectedPoint = new EventEmitter<{ point: XYType, compareIndex: number }>();

  @Input()
  public cumulative: boolean;


  constructor(private ngZone: NgZone) {
  }

  protected onClick(e: { point: XYType, compareIndex: number }): void {
    this.selectedPoint.emit({point: e.point, compareIndex: e.compareIndex});
  }


}
