import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {FidjService} from 'fidj-angular';
import {ProfileService} from '../shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private fidjService: FidjService,
              private profile: ProfileService) {

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    if (this.profile.isDemoMode) {
      return true;
    }

    await this.profile.refreshProfile();
    const loggedIn = this.fidjService.isLoggedIn();
    if (!loggedIn) {
      return false;
    }

    return (await this.fidjService.getRoles()).indexOf('admin') >= 0;
  }

}
