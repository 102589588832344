<ion-grid>
  <ion-row>
    <ion-col id="col-container" size-xs="10">
      <div [style.height.px]="currentHeight" id="canvas-container">
        <canvas #canvasElement></canvas>
      </div>
    </ion-col>
    <ion-col size-xs="2">
      <ion-button (click)="reset(2)" color="light" shape="round" size="small">2</ion-button>
      <br>
      <ion-button (click)="reset(4)" color="light" shape="round" size="small">4</ion-button>
      <br>
      <ion-button (click)="reset(6)" color="light" shape="round" size="small">6</ion-button>
      <br>
    </ion-col>
  </ion-row>
</ion-grid>
