import {CommonModule, NgOptimizedImage, NgStyle} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {ProfileService} from './profile.service';
import {Storage} from './storage.service';
import {RadarService} from './radar.service';
import {Cache} from './cache.service';
import {IonicModule, IonRange} from '@ionic/angular';
import {
  AreInProgressPipe,
  AreReady,
  AreStopped,
  HasGoodQuality,
  HasNotGoodQuality,
  HaveNotBeenRed,
  IsNotReady,
  IsReady,
  ProgressBuffer,
  ProgressValue,
  TimeframeComputationProgress,
  TimeframeQualityProgress,
  WaitForValidation
} from './shared.pipe';
import {RaainMapComponent} from './raain-map/raain-map.component';
import {RaainCompareComponent} from './raain-compare/raain-compare.component';
import {RaainConfigurationComponent} from './raain-configuration/raain-configuration.component';
import {RaainDateFocusComponent} from './raain-date-focus/raain-date-focus.component';
import {RaainSpeedComponent} from './raain-speed/raain-speed.component';
import {RaainCompareStackComponent} from './raain-compare-stack/raain-compare-stack.component';

@NgModule({
  declarations: [
    RaainMapComponent,
    RaainCompareComponent,
    RaainCompareStackComponent,
    RaainDateFocusComponent,
    RaainConfigurationComponent,
    RaainSpeedComponent,

    AreStopped,
    AreInProgressPipe,
    AreReady,
    IsReady,
    IsNotReady,
    WaitForValidation,
    HasGoodQuality,
    HasNotGoodQuality,
    ProgressValue,
    ProgressBuffer,
    HaveNotBeenRed,
    TimeframeComputationProgress,
    TimeframeQualityProgress,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgOptimizedImage,
  ],
  providers: [
    Storage,
    RadarService,
    ProfileService,
    Cache,
    IonRange,
    TimeframeComputationProgress,
    TimeframeQualityProgress,
  ],
  exports: [
    CommonModule,
    NgStyle,

    RaainMapComponent,
    RaainCompareComponent,
    RaainCompareStackComponent,
    RaainDateFocusComponent,
    RaainConfigurationComponent,
    RaainSpeedComponent,

    AreStopped,
    AreInProgressPipe,
    AreReady,
    IsReady,
    IsNotReady,
    WaitForValidation,
    HasGoodQuality,
    HasNotGoodQuality,
    ProgressValue,
    ProgressBuffer,
    HaveNotBeenRed,
    TimeframeComputationProgress,
    TimeframeQualityProgress,
  ]
})
export class SharedModule {
  constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
    if (parentModule) {
      // throw new Error(
      console.warn(
        'SharedModule is already loaded. Import it in the AppModule only');
    }
  }
}
