<ion-grid>
  <ion-row>
    <ion-col id="col-container" size-sm="12">
      <div [style.height.px]="currentHeight" id="canvas-container">
        <canvas #canvasElement></canvas>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="ion-text-right" offset-sm="4" size-sm="1">
      <ion-button (click)="focusPrevious()" color="medium" fill="clear">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col class="ion-text-center" size-sm="2">
      <ion-button (click)="focusReset()" *ngIf="!withoutAll" color="medium" fill="clear">
        all dates
      </ion-button>
    </ion-col>
    <ion-col class="ion-text-left" size-sm="1">
      <ion-button (click)="focusNext()" color="medium" fill="clear">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
